<template>
  <v-card>
    <v-card-title>Account Information</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="First Name"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.first_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Last Name"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Email"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="CRM ID"
              class="dt-text-field"
              outlined
              hide-details
              v-model="form.crm_id"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="password"
              label="Password"
              class="dt-text-field"
              outlined
              v-model="password"
              :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isShowPassword ? 'text' : 'password'"
              :rules="passwordRules"
              @click:append="isShowPassword = !isShowPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="password"
              label="Confirm Password"
              class="dt-text-field"
              outlined
              v-model="confirmPassword"
              :rules="confirmPasswordRules"
              @click:append="isShowConfirm = !isShowConfirm"
              :append-icon="isShowConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="isShowConfirm ? 'text' : 'password'"
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex justify-end my-6" flat tile>
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            @click="saveOnly"
            :loading="loadingSave"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green"
            class="ma-1 white--text"
            @click="saveAndExit"
            :loading="loadingSaveAndExit"
            :disabled="loadingSave || loadingSaveAndExit"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn
            large
            exact
            color="warning"
            class="ma-1 white--text"
            :to="{ name: 'dealers-account-maintenance.sales-consultants.index' }"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  hondaPasswordRequirements,
  confirmPassword,
  minLength
} from '@/config/validations'
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    action: {
      default: "create"
    },
    details: {
      required: false,
      default() {
        return {
          first_name: null,
          last_name: null,
          email: null,
          crm_id: null,
        };
      },
    },
  },
  data() {
    return {
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        crm_id: null,
      },
      isShowPassword: false,
      isShowConfirm: false,
      password: '',
      confirmPassword: '',
      passwordRules: [
        (value) => (!!value || this.action != "create" ) || 'Please type password.',
        (value) => minLength(value, 10),
        hondaPasswordRequirements
      ],
      confirmPasswordRules: [
        (value) => (!!value || this.action != "create" ) || 'Please type confirm password',
        (value) => confirmPassword(value, this.password)
      ],
    };
  },
  mounted() {
    this.form.first_name = this.details.first_name;
    this.form.last_name = this.details.last_name;
    this.form.email = this.details.email;
    this.form.crm_id = this.details.crm_id;
  },
  computed: {
    ...mapGetters({
      dealer: "dealers/GET_DEALER"
    }),
  },
  methods: {
    ...mapActions({
      save: "dealerSalesConsultant/save",
    }),
    async submit() {
      var data = {
        first_name: this.form.first_name,
        last_name: this.form.last_name,
        email: this.form.email,
        crm_id: this.form.crm_id,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      if (this.dealer) {
        data.dealer_id = this.dealer.id;
      }

      const id = this.$route.params.id ? this.$route.params.id : null;

      await this.save({
        id,
        data,
      }).then((data) => {
        this.loadingSave = false;
        this.loadingSaveAndExit = false;
      });
    },
    async saveOnly() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSave = true;

      await this.submit();

      if (!this.$route.params.id) {
        this.$refs.form.reset();
      }
    },
    async saveAndExit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loadingSaveAndExit = true;

      await this.submit();
      
      const user = JSON.parse(localStorage.user);

      if (user.is_admin) { 
        this.$router.push({ name: "dealers-account-maintenance.dealers.salesconsultants", params: { id: this.details[0].dealer.id } });
      } else {
        this.$router.push({ name: "dealers-account-maintenance.sales-consultants.index" });
      }
    },
  },
};
</script>

<style></style>
